<template>
  <div class="Profile__General__Page">
    <div class="Profile__General__Page__Header">
      <h1>ניהול תפריט ראשי בקורס {{ course.name }}</h1>
    </div>
    <div class="importing-step">
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
    </div>
    <div v-if="model" class="Profile__General__Page__Form">
      <h1>*פריטים מסומנים יופיעו בבר הימני בעמודי הקורס</h1>
      <div class="course-navigation-container">
        <b-checkbox name="showHome" id="showHome" v-model="model.showHome">דף הבית</b-checkbox>
        <b-checkbox name="showSyllabus" id="showSyllabus" v-model="model.showNavBarSyllabus">סילבוס</b-checkbox>
        <b-checkbox name="showSyllabus" id="showSyllabus" v-model="model.showNavBarCourseMessages">לוח הודעות</b-checkbox>
        <b-checkbox name="showSyllabus" id="showSyllabus" v-model="model.showNavBarAboutCourse">על הקורס</b-checkbox>
        <b-checkbox name="showSyllabus" id="showSyllabus" v-model="model.showNavBarCourseFiles">קבצים</b-checkbox>
        <b-checkbox name="showSyllabus" id="showSyllabus" v-model="model.showNavBarCalculators">מחשבונים</b-checkbox>
        <b-checkbox name="showSyllabus" id="showSyllabus" v-model="model.showNavBarHelpCenter">מרכז העזרה</b-checkbox>
        <b-checkbox name="showSyllabus" id="showSyllabus" v-model="model.showNavBarNegishot">נגישות</b-checkbox>
        <b-checkbox name="showSyllabus" id="showSyllabus" v-model="model.showNavBarUserCalendar">לוח שנה</b-checkbox>
        <b-checkbox name="showSyllabus" id="showSyllabus" v-model="model.showNavBarCourseForum">פורום</b-checkbox>
        <b-checkbox name="showSyllabus" id="showSyllabus" v-model="model.showNavBarCourseTasks">מטלות</b-checkbox>
        <b-checkbox name="showSyllabus" id="showSyllabus" v-model="model.showNavBarCards">כרטיסיות/מרכז התרגול</b-checkbox>
        <b-checkbox name="showSyllabus" id="showSyllabus" v-model="model.showExpoQuestions">שאלות חשיפה</b-checkbox>
        <b-checkbox name="showSyllabus" id="showSyllabus" v-model="model.showNavBarLecturesRegistrationList">רישום להרצאות</b-checkbox>
        <b-checkbox name="showSyllabus" id="showSyllabus" v-model="model.showNavBarVideos">מרכז ההרצאות</b-checkbox>
        <b-checkbox name="showReadingCenter" id="showReadingCenter" v-model="model.showReadingCenter">ספרי הלימוד</b-checkbox>
        <b-checkbox style="padding-right: 5px;" name="showNavBarLibrary" id="showNavBarLibrary" v-model="model.showNavBarLibrary">במידה ומוצג ספרי הלימוד, האם להציג ספרייה חדשה</b-checkbox>
        <b-checkbox name="showNisuah" id="showNisuah" v-model="model.showNisuah">סימולטור הניסוח</b-checkbox>
        <b-button @click="save" style="width: 30%" type="is-info">שמור</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import AdminService from "../services/AdminService";

export default {
  name: "ProfileGeneral",
  data() {
    return {
      isLoading: false,
      model: null,
    };
  },
  computed: {
    course() {
      return this.$store.state.auth?.course;
    }
  },
  created() {
    this.isLoading = true;
    AdminService.getCourseNavigation(this.course.id)
      .then((r) => {
        this.model = r.data;
        this.isLoading = false;
      });
  },
  methods: {
    save() {
      this.isLoading = true;
      AdminService.changeCourseNavigation(this.course.id, {
        ...this.model
      })
        .then(() => {
          Toast.open({
            type: "is-success",
            message: "הפרטים נשמרו בהצלחה!",
            position: "is-top",
            duration: 4000,
          });
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "הפעולה לא התבצעה. יש לנסות שוב.",
            position: "is-bottom",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
  },
};
</script>

<style lang="scss" scoped>
.course-navigation-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  
}
.item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Profile__General__Page {
  padding: 20px 40px;

  .Profile__General__Page__Header {
    border-bottom: 1px solid silver;
    padding: 0px 10px 10px 10px;

    h1 {
      font-size: 30px;
    }
  }

  .Profile__General__Page__Form {
    margin-top: 20px;
    max-width: 450px;

    h1 {
      margin-bottom: 10px;
    }

    .Profile__General__Page__Form__Field {
      margin-top: 20px;

      ::v-deep {
        .field label {
          font-size: 15px;
          color: rgb(44, 62, 80);
        }

        input {
          background-color: #f5f8fa;
          box-shadow: none;
          border: 1px solid #cbd6e2;

          &:focus {
            border: 2px solid #5fe0ea;
          }
        }
      }
    }

    .Profile__General__Page__Form__Actions {
      ::v-deep .button {
        margin-inline-end: 5px;
        background-color: #4d6884;
        color: white;
        border: none;
        border-radius: 3px;
        padding: 6px 15px;
        line-height: normal;
        height: unset;
        cursor: pointer;
        box-shadow: none;
        transition: transfrom 0.2s linear;

        &:hover {
          background-color: #54779c;
          border: none;
          outline: none;
        }

        &:active {
          transform: scale(0.98);
          border: none;
          outline: none;
        }
      }
    }
  }
}
</style>
