<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ !row ? 'הוספת' : 'עדכון' }} הודעה ללוח</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <b-field label="הזן כותרת">
                        <b-input v-model="model.messageTitle"></b-input>
                    </b-field>
                    <FieldInlineDateTime label="מתאריך" @onChange="onChange" :hide-hover="true"
                        position="is-bottom-left" v-model="model.displayFromDate" />
                    <FieldInlineDateTime label="עד תאריך" @onChange="onChange" :hide-hover="true"
                        position="is-bottom-left" v-model="model.displayToDate" />
                    <b-field label="הזן מחבר">
                        <b-input v-model="model.messageAuthor"></b-input>
                    </b-field>
                    <b-field label="יוצג רק לקבוצה מספר">
                        <b-input v-model="model.teamNumber"></b-input>
                    </b-field>
                    <b-field label="תוכן">
                        <ckeditor :editor="editor" v-model="model.messageBody" :config="editorConfig"></ckeditor>
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import AdminService from "@/services/AdminService";
import { ToastProgrammatic as Toast } from "buefy";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FieldInlineDateTime from "@/components/Fields/FieldInlineDateTime.vue";

export default {
    name: "DialogReportDriverAssign",
    props: ["store", 'row'],
    components: {
        FieldInlineDateTime
    },
    computed: {
        courseId() {
            return this.store.state.auth.course.id;
        },
    },
    created() {
        if (this.row) this.model = this.row;
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            loading: false,
            model: {
                messageTitle: null,
                messageBody: '',
                messageAuthor: null,
                displayFromDate: null,
                displayToDate: null,
                teamNumber: null
            },
            editor: ClassicEditor,
            editorConfig: {
                language: {
                    ui: 'he',
                }
            }
        };
    },
    methods: {
        save() {
            this.isLoading = true;
            if (!this.row) {
                AdminService.addCourseMessage(this.courseId, {
                    ...this.model
                })
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        this.$emit("close");
                        window.location.reload();
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
            } else {
                AdminService.editCourseMessage(this.courseId, this.row.id, {
                    ...this.model
                })
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        this.$emit("close");
                        window.location.reload();
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        onChange() { }
    },
};
</script>

<style scoped lang="scss"></style>
