var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Profile__General__Page" }, [
    _c(
      "div",
      {
        staticClass:
          "\n      Profile__General__Page__Header\n      is-flex is-justify-content-space-between\n    "
      },
      [
        _c("h1", [_vm._v("לוח שנה לקורס " + _vm._s(_vm.course.name))]),
        _c(
          "b-button",
          { attrs: { type: "is-primary" }, on: { click: _vm.addRequest } },
          [_vm._v("הוספת אירוע")]
        )
      ],
      1
    ),
    _c("div", { staticClass: "Profile__General__Page__Form" }, [
      _c(
        "div",
        { staticClass: "Profile__General__Page__Form__Field" },
        [
          _c(
            "b-table",
            {
              attrs: {
                data: _vm.data,
                loading: _vm.isLoading,
                bordered: true,
                striped: true,
                narrowed: false,
                hoverable: false
              }
            },
            [
              _c("b-table-column", {
                attrs: { centered: "", field: "Id", label: "מזהה" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.id) + " ")]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  centered: "",
                  field: "name",
                  label: "שם"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.name) + " ")]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  centered: "",
                  field: "description",
                  label: "תיאור"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.description) + " ")]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  centered: "",
                  field: "status",
                  label: "סטטוס"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.status) + " ")]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }