var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Profile__General__Page" }, [
    _c(
      "div",
      {
        staticClass:
          "\n      Profile__General__Page__Header\n      is-flex is-justify-content-space-between\n    "
      },
      [
        _c("h1", [_vm._v("לוח הודעות לקורס " + _vm._s(_vm.course.name))]),
        _c(
          "b-button",
          {
            attrs: { type: "is-primary" },
            on: { click: _vm.addCourseMessage }
          },
          [_vm._v("הוספת אירוע")]
        )
      ],
      1
    ),
    _c("div", { staticClass: "Profile__General__Page__Form" }, [
      _c(
        "div",
        { staticClass: "Profile__General__Page__Form__Field" },
        [
          _c(
            "b-table",
            {
              attrs: {
                data: _vm.data,
                loading: _vm.isLoading,
                bordered: true,
                striped: true,
                narrowed: false,
                hoverable: false
              }
            },
            [
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  centered: "",
                  field: "name",
                  label: "שם"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.messageTitle) + " ")
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  centered: "",
                  field: "description",
                  label: "תיאור"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(props.row.messageBody) }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  centered: "",
                  field: "author",
                  label: "יוצר"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.messageAuthor) + " ")
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  centered: "",
                  field: "displayFromDate",
                  label: "מתאריך"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.parseDate(props.row.displayFromDate)) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  centered: "",
                  field: "displayToDate",
                  label: "עד תאריך"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.parseDate(props.row.displayToDate)) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: {
                  sortable: "",
                  centered: "",
                  field: "readCount",
                  label: "מספר סימוני קריאה"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.readCount) + " ")]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { centered: "", field: "", label: "עריכה" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: { type: "is-primary" },
                            on: {
                              click: function($event) {
                                return _vm.editMessage(props.row)
                              }
                            }
                          },
                          [_vm._v("ערוך")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }