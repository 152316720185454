var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Profile__General__Page" }, [
    _c("div", { staticClass: "Profile__General__Page__Header" }, [
      _c("h1", [_vm._v("ניהול תפריט ראשי בקורס " + _vm._s(_vm.course.name))])
    ]),
    _c(
      "div",
      { staticClass: "importing-step" },
      [
        _c("b-loading", {
          attrs: { "is-full-page": true, "can-cancel": false },
          model: {
            value: _vm.isLoading,
            callback: function($$v) {
              _vm.isLoading = $$v
            },
            expression: "isLoading"
          }
        })
      ],
      1
    ),
    _vm.model
      ? _c("div", { staticClass: "Profile__General__Page__Form" }, [
          _c("h1", [_vm._v("*פריטים מסומנים יופיעו בבר הימני בעמודי הקורס")]),
          _c(
            "div",
            { staticClass: "course-navigation-container" },
            [
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showHome", id: "showHome" },
                  model: {
                    value: _vm.model.showHome,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showHome", $$v)
                    },
                    expression: "model.showHome"
                  }
                },
                [_vm._v("דף הבית")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showSyllabus", id: "showSyllabus" },
                  model: {
                    value: _vm.model.showNavBarSyllabus,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showNavBarSyllabus", $$v)
                    },
                    expression: "model.showNavBarSyllabus"
                  }
                },
                [_vm._v("סילבוס")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showSyllabus", id: "showSyllabus" },
                  model: {
                    value: _vm.model.showNavBarCourseMessages,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showNavBarCourseMessages", $$v)
                    },
                    expression: "model.showNavBarCourseMessages"
                  }
                },
                [_vm._v("לוח הודעות")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showSyllabus", id: "showSyllabus" },
                  model: {
                    value: _vm.model.showNavBarAboutCourse,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showNavBarAboutCourse", $$v)
                    },
                    expression: "model.showNavBarAboutCourse"
                  }
                },
                [_vm._v("על הקורס")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showSyllabus", id: "showSyllabus" },
                  model: {
                    value: _vm.model.showNavBarCourseFiles,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showNavBarCourseFiles", $$v)
                    },
                    expression: "model.showNavBarCourseFiles"
                  }
                },
                [_vm._v("קבצים")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showSyllabus", id: "showSyllabus" },
                  model: {
                    value: _vm.model.showNavBarCalculators,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showNavBarCalculators", $$v)
                    },
                    expression: "model.showNavBarCalculators"
                  }
                },
                [_vm._v("מחשבונים")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showSyllabus", id: "showSyllabus" },
                  model: {
                    value: _vm.model.showNavBarHelpCenter,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showNavBarHelpCenter", $$v)
                    },
                    expression: "model.showNavBarHelpCenter"
                  }
                },
                [_vm._v("מרכז העזרה")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showSyllabus", id: "showSyllabus" },
                  model: {
                    value: _vm.model.showNavBarNegishot,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showNavBarNegishot", $$v)
                    },
                    expression: "model.showNavBarNegishot"
                  }
                },
                [_vm._v("נגישות")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showSyllabus", id: "showSyllabus" },
                  model: {
                    value: _vm.model.showNavBarUserCalendar,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showNavBarUserCalendar", $$v)
                    },
                    expression: "model.showNavBarUserCalendar"
                  }
                },
                [_vm._v("לוח שנה")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showSyllabus", id: "showSyllabus" },
                  model: {
                    value: _vm.model.showNavBarCourseForum,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showNavBarCourseForum", $$v)
                    },
                    expression: "model.showNavBarCourseForum"
                  }
                },
                [_vm._v("פורום")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showSyllabus", id: "showSyllabus" },
                  model: {
                    value: _vm.model.showNavBarCourseTasks,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showNavBarCourseTasks", $$v)
                    },
                    expression: "model.showNavBarCourseTasks"
                  }
                },
                [_vm._v("מטלות")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showSyllabus", id: "showSyllabus" },
                  model: {
                    value: _vm.model.showNavBarCards,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showNavBarCards", $$v)
                    },
                    expression: "model.showNavBarCards"
                  }
                },
                [_vm._v("כרטיסיות/מרכז התרגול")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showSyllabus", id: "showSyllabus" },
                  model: {
                    value: _vm.model.showExpoQuestions,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showExpoQuestions", $$v)
                    },
                    expression: "model.showExpoQuestions"
                  }
                },
                [_vm._v("שאלות חשיפה")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showSyllabus", id: "showSyllabus" },
                  model: {
                    value: _vm.model.showNavBarLecturesRegistrationList,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.model,
                        "showNavBarLecturesRegistrationList",
                        $$v
                      )
                    },
                    expression: "model.showNavBarLecturesRegistrationList"
                  }
                },
                [_vm._v("רישום להרצאות")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showSyllabus", id: "showSyllabus" },
                  model: {
                    value: _vm.model.showNavBarVideos,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showNavBarVideos", $$v)
                    },
                    expression: "model.showNavBarVideos"
                  }
                },
                [_vm._v("מרכז ההרצאות")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showReadingCenter", id: "showReadingCenter" },
                  model: {
                    value: _vm.model.showReadingCenter,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showReadingCenter", $$v)
                    },
                    expression: "model.showReadingCenter"
                  }
                },
                [_vm._v("ספרי הלימוד")]
              ),
              _c(
                "b-checkbox",
                {
                  staticStyle: { "padding-right": "5px" },
                  attrs: { name: "showNavBarLibrary", id: "showNavBarLibrary" },
                  model: {
                    value: _vm.model.showNavBarLibrary,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showNavBarLibrary", $$v)
                    },
                    expression: "model.showNavBarLibrary"
                  }
                },
                [_vm._v("במידה ומוצג ספרי הלימוד, האם להציג ספרייה חדשה")]
              ),
              _c(
                "b-checkbox",
                {
                  attrs: { name: "showNisuah", id: "showNisuah" },
                  model: {
                    value: _vm.model.showNisuah,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "showNisuah", $$v)
                    },
                    expression: "model.showNisuah"
                  }
                },
                [_vm._v("סימולטור הניסוח")]
              ),
              _c(
                "b-button",
                {
                  staticStyle: { width: "30%" },
                  attrs: { type: "is-info" },
                  on: { click: _vm.save }
                },
                [_vm._v("שמור")]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }