var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Profile__General__Page" }, [
    _vm._m(0),
    _c("div", { staticClass: "Profile__General__Page__Form" }, [
      _c(
        "div",
        {
          staticClass: "Profile__General__Page__Form__Field",
          staticStyle: { display: "flex", gap: "20px" }
        },
        [
          _c(
            "b-field",
            { attrs: { label: "פרופיל מנוהל" } },
            [
              _c(
                "b-switch",
                {
                  on: { input: _vm.onChangeActive },
                  model: {
                    value: _vm.model.IsManaged,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "IsManaged", $$v)
                    },
                    expression: "model.IsManaged"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.model.IsManaged ? "כן" : "לא") + " ")]
              )
            ],
            1
          ),
          _vm.isAdmin
            ? _c(
                "b-field",
                { attrs: { label: "פרופיל מנוהל באוטומציה" } },
                [
                  _c(
                    "b-switch",
                    {
                      attrs: { disabled: !_vm.model.IsManaged },
                      on: { input: _vm.onChangeAutomation },
                      model: {
                        value: _vm.model.IsInAutomation,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "IsInAutomation", $$v)
                        },
                        expression: "model.IsInAutomation"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.model.IsInAutomation ? "כן" : "לא") +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isAdmin
            ? _c(
                "b-field",
                { attrs: { label: "הסבה לילית" } },
                [
                  _c(
                    "b-switch",
                    {
                      attrs: { disabled: !_vm.model.IsManaged },
                      on: { input: _vm.onChangeNightRunning },
                      model: {
                        value: _vm.IsOnNightRunning,
                        callback: function($$v) {
                          _vm.IsOnNightRunning = $$v
                        },
                        expression: "IsOnNightRunning"
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.IsOnNightRunning ? "כן" : "לא") + " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isAutomationLoading
            ? _c(
                "div",
                [
                  _c("b-loading", {
                    attrs: { "is-full-page": false, "can-cancel": false },
                    model: {
                      value: _vm.isAutomationLoading,
                      callback: function($$v) {
                        _vm.isAutomationLoading = $$v
                      },
                      expression: "isAutomationLoading"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "Profile__General__Page__Form__Field" },
        [
          _c(
            "b-field",
            { attrs: { label: "נבדק לאחרונה בתאריך" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    padding: "0 10px",
                    direction: "ltr",
                    "min-width": "144px",
                    border: "1px solid #cbd6e2"
                  }
                },
                [_vm._v(_vm._s(_vm.parseDate(_vm.model.LastCheckDate)))]
              ),
              _c(
                "b-button",
                {
                  attrs: {
                    loading: _vm.isUpdatingCheckDate,
                    type: "is-primary"
                  },
                  on: { click: _vm.updateCheckDate }
                },
                [_vm._v("עדכן")]
              )
            ],
            1
          ),
          _c(
            "b-field",
            { attrs: { label: "סיסמה למרכז הקנסות" } },
            [
              _c("b-input", {
                attrs: { type: "password", "password-reveal": "" },
                model: {
                  value: _vm.model.FineCenterPassword,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "FineCenterPassword", $$v)
                  },
                  expression: "model.FineCenterPassword"
                }
              }),
              _c(
                "b-button",
                {
                  attrs: { loading: _vm.isSavingPassword, type: "is-primary" },
                  on: { click: _vm.savePassword }
                },
                [_vm._v("עדכן")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "Profile__General__Page__Form__Field" },
        [
          _c("h3", [_vm._v("הגדרות מערכת ניתור")]),
          _c(
            "b-table",
            {
              attrs: {
                data: _vm.model.ProfileTrackingProviders,
                bordered: true,
                striped: true,
                narrowed: false,
                hoverable: false
              }
            },
            [
              _c("b-table-column", {
                attrs: { centered: "", field: "Id", label: "סוג" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "b-select",
                          {
                            attrs: { placeholder: "בחר סוג" },
                            model: {
                              value: props.row.Type.Id,
                              callback: function($$v) {
                                _vm.$set(props.row.Type, "Id", $$v)
                              },
                              expression: "props.row.Type.Id"
                            }
                          },
                          [
                            _c("option", { domProps: { value: 1 } }, [
                              _vm._v("איתוראן")
                            ]),
                            _c("option", { domProps: { value: 2 } }, [
                              _vm._v("פויינטר")
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { centered: "", field: "UserName", label: "שם משתמש" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c("b-input", {
                          model: {
                            value: props.row.UserName,
                            callback: function($$v) {
                              _vm.$set(props.row, "UserName", $$v)
                            },
                            expression: "props.row.UserName"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: {
                  centered: "",
                  field: "Password",
                  label: "סיסמה",
                  width: "200"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c("b-input", {
                          attrs: { type: "password", "password-reveal": "" },
                          model: {
                            value: props.row.Password,
                            callback: function($$v) {
                              _vm.$set(props.row, "Password", $$v)
                            },
                            expression: "props.row.Password"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { centered: "", field: "IsActive", label: "פעיל" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "b-switch",
                          {
                            model: {
                              value: props.row.IsActive,
                              callback: function($$v) {
                                _vm.$set(props.row, "IsActive", $$v)
                              },
                              expression: "props.row.IsActive"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(props.row.IsActive ? "כן" : "לא") +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { centered: "", field: "IsActive", label: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              loading: _vm.isSavingProfileTracking,
                              type: "is-primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.updateProfileTracking(props.row)
                              }
                            }
                          },
                          [_vm._v(" עדכן ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Profile__General__Page__Header" }, [
      _c("h1", [_vm._v("הגדרות פרופיל")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }