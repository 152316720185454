<template>
  <div class="Profile__Page">
      <div class="Profile__Page__Menu">
        <h1>הגדרות קורס</h1>
        <ul>
          <li>
            <router-link to="general">
                הגדרות כלליות
            </router-link>
            <router-link to="messages">
                לוח הודעות
            </router-link>
          </li>
          <li >
            <router-link to="navigation">
              תפריט ניווט
            </router-link>
          </li>
          <li>
            <router-link to="calendar">
              לוח שנה לקורס
            </router-link>
          </li>
          <li >
            <router-link to="AdminCourseRequests">
              מרכז פניות
            </router-link>
          </li>
        </ul>
      </div>
      <div class="Profile__Page__Content">
        <router-view v-if="ready" />
      </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',

  async created() {
    this.ready = true;
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === 'True';
    },
  },
  data() {
    return {
      ready: false,
    };
  },
};
</script>
<style lang="scss" scoped>
  .Profile__Page {
    display: flex;

    .Profile__Page__Menu {
      width: 300px;
      background-color: #F5F8FA;
      min-height: calc(100vh - 50px);
      padding: 20px;

      h1 {
        font-size: 25px;
        padding: 5px 10px;
      }
      ul {
        margin-top: 10px;

        li {
          cursor: pointer;

          a {
            color: inherit;
            padding: 10px 10px;
            font-size: 16px;
            width: 100%;
            display: block;
            border-radius: 3px;

            &.router-link-exact-active {
              background-color: #EAF0F6;
              font-weight: 500;
            }

            &:hover {
              background-color: #EAF0F6;
            }
          }
        }
      }
    }
    .Profile__Page__Content {
      flex: 1;
      border-right: 1px solid #c0c0c09e
    }
  }
</style>
