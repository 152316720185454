var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Profile__General__Page" }, [
    _c(
      "div",
      {
        staticClass:
          "\n      Profile__General__Page__Header\n      is-flex is-justify-content-space-between\n    "
      },
      [
        _c("h1", [
          _vm._v("ניהול נושאים במרכז הפניות בקורס " + _vm._s(_vm.course.name))
        ]),
        _c(
          "b-button",
          { attrs: { type: "is-primary" }, on: { click: _vm.addMessageType } },
          [_vm._v("הוספת נושא לפנייה")]
        )
      ],
      1
    ),
    _c("div", { staticClass: "Profile__General__Page__Form" }, [
      _c(
        "div",
        { staticClass: "Profile__General__Page__Form__Field" },
        [
          _c(
            "b-table",
            {
              attrs: {
                data: _vm.data,
                loading: _vm.isLoading,
                bordered: true,
                striped: true,
                narrowed: false,
                hoverable: false
              }
            },
            [
              _c("b-table-column", {
                attrs: { centered: "", field: "Id", label: "מזהה" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.id) + " ")]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { centered: "", field: "name", label: "שם" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.displayName) + " ")]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { centered: "", field: "description", label: "תיאור" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.description) + " ")]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { centered: "", field: "status", label: "סטטוס" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(props.row.status ? "פעיל" : "לא פעיל") +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { centered: "", field: "sortOrder", label: "סדר" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.sortOrder) + " ")]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: {
                  centered: "",
                  field: "toDayOfWeek",
                  label: "ימי פעילות"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getFormatDay(props.row.toDayOfWeek)) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: {
                  centered: "",
                  field: "toHour",
                  label: "שעת סיום פעילות"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.getFormatHour(props.row.toHour)) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { centered: "", field: "", label: "עריכה" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: { type: "is-primary" },
                            on: {
                              click: function($event) {
                                return _vm.editMessageType(props.row)
                              }
                            }
                          },
                          [_vm._v("ערוך")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }