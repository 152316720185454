<template>
  <div class="Profile__General__Page">
    <div class="
        Profile__General__Page__Header
        is-flex is-justify-content-space-between
      ">
      <h1>ניהול נושאים במרכז הפניות בקורס {{ course.name }}</h1>
      <b-button @click="addMessageType" type="is-primary">הוספת נושא לפנייה</b-button>
    </div>
    <div class="Profile__General__Page__Form">
      <div class="Profile__General__Page__Form__Field">
        <b-table :data="data" :loading="isLoading" :bordered="true" :striped="true" :narrowed="false"
          :hoverable="false">
          <b-table-column centered field="Id" label="מזהה" v-slot="props">
            {{ props.row.id }}
          </b-table-column>
          <b-table-column centered field="name" label="שם" v-slot="props">
            {{ props.row.displayName }}
          </b-table-column>
          <b-table-column centered field="description" label="תיאור" v-slot="props">
            {{ props.row.description }}
          </b-table-column>
          <b-table-column centered field="status" label="סטטוס" v-slot="props">
            {{ props.row.status ? 'פעיל' : 'לא פעיל' }}
          </b-table-column>
          <b-table-column centered field="sortOrder" label="סדר" v-slot="props">
            {{ props.row.sortOrder }}
          </b-table-column>
          <b-table-column centered field="toDayOfWeek" label="ימי פעילות" v-slot="props">
            {{ getFormatDay(props.row.toDayOfWeek) }}
          </b-table-column>
          <b-table-column centered field="toHour" label="שעת סיום פעילות" v-slot="props">
            {{ getFormatHour(props.row.toHour) }}
          </b-table-column>
          <b-table-column centered field="" label="עריכה" v-slot="props">
            <b-button @click="editMessageType(props.row)" type="is-primary">ערוך</b-button>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from '../services/AdminService';
import DialogMessageType from "../components/DialogMessageType.vue";
import { ModalProgrammatic as Modal } from "buefy";
import DialogCourseMessage from '../components/DialogCourseMessage.vue';

export default {
  name: "AdminCourseRequests",
  data() {
    return {
      isLoading: true,
      data: [],
    };
  },
  computed: {
    course() {
      return this.$store.state.auth?.course;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.isLoading = true;
      AdminService.getAdminCourseRequest(this.course.id)
        .then((r) => {
          this.data = r.data;
          this.isLoading = false;
        });
    },
    getFormatDay(dayValue) {
      switch (dayValue) {
        case 1:
          return "יום ראשון"
        case 2:
          return "יום שני"
        case 3:
          return "יום שלישי"
        case 4:
          return "יום רביעי"
        case 5:
          return "יום חמישי"
        case 6:
          return "יום שישי"
        default:
          return "";
      }
    },
    getFormatHour(hourValue) {
      if (!hourValue) return null;
      const time = hourValue.toString().split('.');
      if (!time[1]) {
        return `${time[0]}:00`
      }
      console.log(time[0]);
      const hours = +time[0] > 9 ? +time[0] : `0${time[0]}`;
      const minutes = +time[1] * 60 / 10;
      const formatMinutes = minutes.toString().split("").slice(0, 2).join("");
      return `${hours}:${formatMinutes}`
    },
    addMessageType() {
      Modal.open({
        component: DialogMessageType,
        customClass: "entity-modal",
        props: {
          store: this.$store,
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    editMessageType(row) {
      Modal.open({
        component: DialogMessageType,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          row
        },
        canCancel: false,
        trapFocus: true,
      });
    },
  },
  watch: {
    // async profileId(newValue) {
    //   if (newValue) {
    //     const users = await this.getUsers(newValue);
    //     this.users = [...users];
    //     this.isLoading = false;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.Profile__General__Page {
  padding: 20px 40px;

  .Profile__General__Page__Header {
    border-bottom: 1px solid silver;
    padding: 0px 10px 10px 10px;

    h1 {
      font-size: 30px;
    }
  }

  .Profile__General__Page__Form {
    margin-top: 20px;

    .Profile__General__Page__Form__Field {
      margin-top: 20px;

      .switch,
      .control {
        direction: ltr;
      }

      ::v-deep {
        .field label {
          font-size: 15px;
          color: rgb(44, 62, 80);
        }

        input {
          background-color: #f5f8fa;
          box-shadow: none;
          border: 1px solid #cbd6e2;

          &:focus {
            border: 2px solid #5fe0ea;
          }
        }
      }
    }

    .Profile__General__Page__Form__Actions {
      ::v-deep .button {
        margin-inline-end: 5px;
        background-color: #4d6884;
        color: white;
        border: none;
        border-radius: 3px;
        padding: 6px 15px;
        line-height: normal;
        height: unset;
        cursor: pointer;
        box-shadow: none;
        transition: transfrom 0.2s linear;

        &:hover {
          background-color: #54779c;
          border: none;
          outline: none;
        }

        &:active {
          transform: scale(0.98);
          border: none;
          outline: none;
        }
      }
    }
  }
}
</style>
